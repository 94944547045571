$primary: #E7751D;
$secondary: #CF4520;

$red: #DC3944;
$red-light: #E9573D; //
$green: #19B477; //
$black: #000;
$black-light: #555555;
$white: #FFFFFF;

$grey: #DDD;
$grey-light: #F7F7F7;
$grey-dark: #20252B; //

$solid-grey: #666666;
$solid-grey-light: #E8E8E9;
$solid-grey-dark: #3F3F3F;
$solid-light-blue: #D6EEF6; //

$success: $green; //
$danger: $red;

$body-background: $white;
$body-color: $black-light;