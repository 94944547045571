@use "variable/color" as color;
@use "variable/breakpoint" as breakpoint;
@use "variable/container" as container;
@use "variable/form" as form;
@use "variable/common" as common;

$spacer: 1rem;

@forward "bootstrap-library" with (
  $primary: color.$primary,
  $success: color.$success,
  $danger: color.$danger,
  $body-bg: color.$body-background,
  $body-color: color.$black-light,
  $font-size-base: common.$font-size-base,
  $font-size-lg: common.$font-size-lg,
  $font-size-sm: common.$font-size-sm,
  $font-family-base: 'Arial',
  $grid-columns: 12,
  $headings-margin-bottom: 0,
  $headings-font-weight: 300,
  $grid-gutter-width: 1.5rem,
  $enable-caret: false,
  $enable-shadows: false,
  $grid-breakpoints: (
    xs: breakpoint.$xs,
    sm: breakpoint.$sm,
    md: breakpoint.$md,
    lg: breakpoint.$lg,
    xl: breakpoint.$xl
  ),
  $font-weight-lighter: lighter,
  $font-weight-light: 300,
  $font-weight-normal: 400,
  $font-weight-bold: 700,
  $font-weight-bolder: bolder,

  $container-max-widths: (
  sm: container.$sm,
  md: container.$md,
  lg: container.$lg,
  xl: container.$xl,
  ),
  $spacer: $spacer,
  $spacers: (
    0: 0,
    1: $spacer * .25,   // 4px
    2: $spacer * .5,    // 8px
    3: $spacer * .75,   // 12px
    4: $spacer,         // 16px
    5: $spacer * 1.25,  // 20px
    6: $spacer * 1.5,   // 24px
    7: $spacer * 1.75,  // 28px
    8: $spacer * 2,     // 32px
    9: $spacer * 2.25,  // 36px
    10: $spacer * 2.5,  // 40px
    11: $spacer * 2.75, // 44px
    12: $spacer * 3,    // 48px
  ),
  $form-check-input-width: 1.25em,
  $form-check-padding-start: 1.8em,
  $form-check-input-border: 1px solid color.$solid-grey-light,
  $form-check-input-border-radius: 4px,
  $form-check-input-active-filter: none,
  $form-check-input-focus-box-shadow: 0 0 0 0.1rem rgb(color.$primary, .25),
  $form-check-input-focus-border: color.$solid-grey-light,
  $form-check-input-checked-bg-color: white,
  $form-check-input-checked-border-color: 1px solid color.$solid-grey-light,
  $form-check-input-checked-color: color.$primary,
  $progress-border-radius: 16px,
  $progress-bg: #EBEBEB,

  // Button
  $btn-font-size: 1rem,
  $btn-color: color.$black-light,
  $btn-font-family: Arial,
  $btn-link-color: color.$black-light,
  $btn-line-height: 24px,
  $btn-padding-x: 14px,
  $btn-padding-y: 6px,
  $btn-font-weight: 700,
  $btn-border-radius: 4px,
  $btn-border-width: 2px,

  // Pagination
  $pagination-color: color.$black,
  $pagination-border-color: color.$white,
  $pagination-active-border-color: color.$white,
  $pagination-hover-border-color: color.$white,
  $pagination-hover-bg: color.$white,
  $pagination-disabled-color: color.$black-light,
  $pagination-disabled-bg: color.$white,
  $pagination-disabled-border-color: color.$white,
  $pagination-focus-box-shadow: none,

  // Dropdown
  $dropdown-padding-x: 1rem,
  $dropdown-padding-y: .5rem,
  $dropdown-link-color: color.$black,
  $dropdown-item-padding-x: 0,
  $dropdown-item-padding-y: .5rem,
  $dropdown-link-hover-bg: color.$white,
  $dropdown-link-hover-color: color.$primary,
  $dropdown-link-active-bg: color.$white,
  $dropdown-link-active-color: color.$primary,
  $dropdown-border-radius: 4px,
  $dropdown-box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.10),

  // Input
  $input-color: form.$input-color,
  $input-height: form.$input-height,
  $input-placeholder-color: form.$input-placeholder,
  $input-height-lg: form.$input-height-lg,
  $input-height-sm: form.$input-height-sm,
  $input-btn-padding-y: .438rem,
  $input-btn-padding-x: 1rem,
  $input-border-color: form.$input-border-color,
  $input-border-radius: form.$input-border-radius,
  $input-border-radius-lg: form.$input-border-radius,
  $input-border-radius-sm: form.$input-border-radius,
  $input-disabled-color: form.$input-color-disabled,
  $input-disabled-bg: form.$input-background-color-disabled,
  $input-disabled-border-color: form.$input-border-color-disabled,

  // Nav > Tabs
  $nav-tabs-border-width: 3px,
  $nav-tabs-border-color: transparent,
  $nav-tabs-link-hover-border-color: transparent,
  $nav-tabs-link-active-color: color.$black,
  $nav-tabs-link-active-border-color: color.$black,

  // Modal
  $modal-content-border-width: 0,
  $modal-content-border-radius: 4px,
  $modal-content-color: color.$black,
);

$colors: (
  "solid-grey": color.$solid-grey,
  "solid-grey-light": color.$solid-grey-light,
  "solid-grey-dark": color.$solid-grey-dark,
  "solid-light-blue": color.$solid-light-blue
) !default;

@each $key, $value in $colors {
  .bg-#{$key} {
    background-color: $value !important;
  }

  .text-#{$key} {
    color: $value !important;
  }
}

