@use "variable/color";

ag-grid-angular {

  .ag-header {
    font-size: 13px;
  }

  .ag-checkbox {
    border-color: transparent !important;
  }

  .ag-checkbox {
    border: 1px solid #D8D8D8;
    border-radius: 4px;
  }

  .ag-cell-wrapper {
    height: 100%;
  }

  .ag-cell {
    line-height: calc(var(--ag-internal-calculated-line-height) - 14px);
  }

  .ag-row {
    border: 1px solid color.$solid-grey-light;
    border-radius: 4px;
    overflow: hidden;
  }
}

.ag-theme-alpine .ag-overlay-no-rows-wrapper.ag-layout-auto-height, .ag-theme-alpine-dark .ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  align-items: inherit;
  padding-top: 80px;
}