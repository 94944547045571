@use "vendor/bootstrap" as bootstrap;

body, html {
  font-size: 16px;

  .text-danger {
    color: bootstrap.$danger !important;
  }

  .cut-long-text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
