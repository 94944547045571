@use "variable/form";
@use "variable/color";
@use "vendor/bootstrap";

.btn {
  .icon {
    display: flex;
    color: inherit;

    width: 20px;
    height: 20px;
  }

  &.btn-lg {
    // $padding-y, $padding-x, $font-size, $border-radius
    @include bootstrap.button-size(10px, 18px, 1rem, 4px);

    .icon {
      display: flex;
      color: inherit;

      width: 24px;
      height: 24px;
    }
  }

  &.btn-sm {
    @include bootstrap.button-size(2px, 10px, .875rem, 4px);

    .icon {
      display: flex;
      color: inherit;

      width: 18px;
      height: 18px;
    }
  }

  &.ignore-outline-focus {
    &:focus-visible, &:focus-visible:active {
      box-shadow: none;
    }
  }

  &.btn-primary {
    // background, border, color
    @include bootstrap.button-variant(
        color.$primary, color.$primary, color.$white, // normal
        color.$secondary, color.$secondary, color.$white, // hover
        color.$secondary, color.$secondary, color.$white, // active
        color.$grey, color.$grey, color.$white // disabled
    );
  }

  &.btn-primary-normal {
    // background, border, color
    @include bootstrap.button-variant(
        transparent, transparent, color.$primary, // normal
        transparent, transparent, color.$secondary, // hover
        transparent, transparent, color.$secondary, // active
        transparent, transparent, color.$grey // disabled
    );
  }

  &.btn-outline-primary {
    // color, color-hover, active-background, active-border, active-color
    @include bootstrap.button-outline-variant(color.$primary, color.$white, color.$primary, color.$primary, color.$white);

    &.btn-fill {
      background-color: color.$white;

      &:hover {
        background-color: color.$primary;
      }

      &:focus-visible {
        background-color: color.$primary;
        color: color.$white;
      }
    }

    &:disabled {
      background-color: color.$grey;
      color: color.$solid-grey;
      border-color: color.$solid-grey;
    }
  }

  &.btn-link {
    &:focus-visible {
      color: color.$primary;
    }
  }

  &.btn-secondary {
    // background, border, color
    @include bootstrap.button-variant(
        color.$secondary, color.$secondary, color.$white, // normal
        color.$primary, color.$primary, color.$white, // hover
        color.$primary, color.$primary, color.$white, // active
        color.$grey, color.$grey, color.$white // disabled
    );
  }

  &.btn-navigate {
    @include bootstrap.button-variant(
        color.$white, color.$white, color.$black-light, // normal
        color.$grey-light, color.$grey-light, color.$black-light, // hover
        color.$primary, color.$primary, color.$white, // active
        color.$grey, color.$grey, color.$white // disabled
    );
  }

  &.btn-outline-secondary {
    // color, color-hover, active-background, active-border, active-color
    @include bootstrap.button-outline-variant(color.$secondary, color.$white, color.$secondary, color.$secondary, color.$white);

    &.btn-fill {
      background-color: color.$white;

      &:hover {
        background-color: color.$secondary;
      }

      &:focus-visible {
        background-color: color.$secondary;
        color: color.$white;
      }
    }

    &:disabled {
      background-color: color.$grey;
      color: color.$solid-grey;
      border-color: color.$solid-grey;
    }
  }
}