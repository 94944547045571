@use "variable/form" as form;
@use "variable/color" as color;

$ng-select-highlight: transparent !default;
$ng-select-primary-text: color.$black;
$ng-select-disabled-text: form.$input-color-disabled;
$ng-select-border: form.$input-border-color;
$ng-select-border-radius: form.$input-border-radius;
$ng-select-bg: #ffffff !default;
$ng-select-selected: form.$select-marked;
$ng-select-selected-text: $ng-select-primary-text !default;

$ng-select-marked: #F7F7F7;
$ng-select-marked-text: $ng-select-primary-text !default;

$ng-select-box-shadow: 0 0 0 0.25rem rgba(231, 117, 29, 0.25);
$ng-select-placeholder: form.$input-placeholder;
$ng-select-height: form.$input-height;
$ng-select-value-padding-left: .5rem;
$ng-select-value-font-size: 1rem;
$ng-select-value-text: $ng-select-primary-text !default;

$ng-select-dropdown-bg: $ng-select-bg !default;
$ng-select-dropdown-border: $ng-select-border !default;
$ng-select-dropdown-optgroup-text: rgba(0, 0, 0, 0.54) !default;
$ng-select-dropdown-optgroup-marked: $ng-select-dropdown-optgroup-text !default;
$ng-select-dropdown-option-bg: $ng-select-dropdown-bg !default;
$ng-select-dropdown-option-text: rgba(0, 0, 0, 0.87) !default;
$ng-select-dropdown-option-disabled: lighten($ng-select-primary-text, 60) !default;

$ng-select-input-text: form.$input-color !default;

$ng-select-disabled-text: form.$input-background-color-disabled;

@import "node_modules/@ng-select/ng-select/scss/default.theme";
