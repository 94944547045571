@use '../variable/form' as form;

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-size: .8125rem;
    font-weight: 500;
    padding-bottom: .5rem;

    color: form.$label-color;
  }

  & > app-validation-handler, & > .text-danger {
    display: none;
  }
}

@mixin validation() {
  color: form.$input-invalid-color;
  border-color: form.$input-invalid-color;

  &::placeholder {
    color: form.$input-invalid-color;
  }

  & + app-validation-handler, & + .text-danger {
    display: block;
    padding-top: .5rem;
    font-size: .8125rem;
  }

  // ng-select
  .ng-select-container {
    color: form.$input-invalid-color !important;
    border-color: form.$input-invalid-color !important;

    .ng-placeholder {
      color: form.$input-invalid-color !important;
    }
  }

  ng-dropdown-panel {
    border-color: form.$input-invalid-color !important;
    border-top: inherit;
  }

  // Custom element
  input.custom-control-value-element, textarea.custom-control-value-element {
    color: form.$input-invalid-color;
    border-color: form.$input-invalid-color;

    &::placeholder {
      color: form.$input-invalid-color;
    }
  }
}

form.validation-after-submit.ng-invalid.ng-submitted {
  .ng-invalid:not(:disabled,.ng-select-disabled) {
    @include validation;
  }
}

form.validation-after-touch.ng-invalid {
  .ng-invalid.ng-dirty:not(:disabled,.ng-select-disabled) {
    @include validation;
  }
}
