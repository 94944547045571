@use "variable/color";
@use "variable/breakpoint";

.page {
  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    margin-bottom: 1rem;

    h1 {
      font-size: 1.5rem;
      color: color.$secondary;
    }

    app-back {
      button {
        font-size: 1.5rem;
        color: color.$primary;
        font-weight: 700;

        i-tabler {
          width: 24px !important;
          height: 24px !important;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      width: 100%;

      button {
        display: flex;
        justify-content: center;
        min-width: 100px;
      }
    }

    @media(min-width: breakpoint.$sm) {
      flex-direction: row;
      justify-content: space-between;

      .actions {
        flex-direction: row;
        width: auto;
      }
    }
  }
}