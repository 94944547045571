@use "variable/form";
@use "variable/common";
@use "variable/color";

@mixin ng-select-size($font-size, $input-height, $input-border-radius, $input-padding-y, $input-padding-x, $border-radius) {
  font-size: $font-size;

  &.ng-select-single {
    .ng-select-container {
      height: $input-height;

      .ng-value-container {
        padding: $input-padding-y $input-padding-x;

        .ng-input {
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          padding-left: $input-padding-x;
        }
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container {
      justify-content: space-between;
      height: $input-height;

      .ng-value-container {
        padding: $input-padding-y $input-padding-x;
        padding-left: $input-padding-x !important;

        .ng-value {
          border-radius: $border-radius;
        }
      }
    }
  }

  .ng-select-container {
    justify-content: space-between;
    width: 100%;
    min-height: $input-height;
    border-radius: $input-border-radius;

    overflow: visible;

    .ng-value-container {
      max-width: calc(100% - 43px);
      padding: $input-padding-y $input-padding-x;

      .ng-placeholder {
        top: auto !important;
        padding: unset !important;
      }
    }
  }
}

ng-select {
  @include ng-select-size(
      common.$font-size-base,
      form.$input-height,
      form.$input-border-radius,
      .3rem,
      1rem,
      .5rem
  );

  // Disabled
  &.ng-select-disabled {
    .ng-value-container > .ng-value > .ng-value-label {
      color: form.$input-color-disabled;
    }
  }

  .ng-value {
    background-color: transparent !important;
    margin-bottom: 0 !important;
  }

  // Clear
  .ng-clear-wrapper {
    width: 18px;
    color: color.$black !important;

    .ng-clear {
      display: flex !important;
    }

    &:hover {
      .ng-clear {
        color: color.$primary !important;
      }
    }
  }

  // Custom arrow
  span.ng-arrow-wrapper .ng-arrow {
    display: none !important;
  }


  span.ng-arrow-wrapper:before {
    content: "\e5cf";
    display: inline-block;
    font: normal normal normal 14px/1 'Material Icons';
    font-size: 20px;
    text-rendering: auto;

    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  &.ng-select-opened .ng-arrow-wrapper:before {
    transform: translateY(-50%) rotate(180deg);
  }

  // Size (normal, sm, lg)
  &.ng-select-sm {
    @include ng-select-size(
        common.$font-size-sm,
        form.$input-height-sm,
        form.$input-border-radius,
        .5rem,
        .5rem,
        .5rem,
    );
  }

  &.ng-select-lg {
    @include ng-select-size(
        common.$font-size-lg,
        form.$input-height-lg,
        form.$input-border-radius,
        .5rem,
        .5rem,
        .5rem,
    );
  }
}

ng-select.ng-select-multiple {
  div.ng-value {
    .selected-value {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .selected-elements-number {
      font-weight: 400;
    }
  }

  .ng-input {
    display: none;
  }

  .ng-multi-label-tmp {
    position: absolute;

    display: inline-block;
    white-space: nowrap;
    width: calc(100% - 60px);
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
