@use "variable/color";

tabset.tab-container {
  ul {
    li.nav-item {
      a.nav-link {
        border: none;
        font-weight: 600;
        color: color.$black-light;

        &.active {
          border-bottom: 3px solid color.$black;
        }

        &:focus-visible {
          color: color.$primary;
          box-shadow: none;
        }
      }
    }
  }
}