@use "color" as color;

// Input
$input-placeholder: #6F7072;
$input-height: 40px;
$input-height-sm: 32px;
$input-height-lg: 48px;
$input-color: color.$black;
$input-color-disabled: #555555;
$input-border-color: #DDD;
$input-border-width: 1px;
$input-border-radius: 4px;
$input-border-color-focus: #9B9B9B;
$input-border-color-disabled: #DDD;
$input-invalid-color: color.$danger;
$input-background-color: color.$white;
$input-background-color-disabled: #F7F7F7;

$select-arrow-color: #505359;
$select-arrow-color-disabled: #6F7072;

$select-clear-color: #505359;
$select-clear-color-hover: color.$primary;

$select-marked: rgba(color.$primary, .1);

// Label
$label-color: color.$black;

// Textarea
$textarea-placeholder: color.$grey;
$textarea-color: color.$black;
$textarea-border-color: color.$solid-grey-light;